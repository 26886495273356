<template>
  <div class="page page-content">
    <div>
      <Block :title="websiteName">
        <template v-slot:header-action>
          <v-btn :disabled="!hasUnpublishedItems" elevation="0" @click="publish" :loading="isPublishing" color="primary">Publier</v-btn>
        </template>

        <span>{{websiteUrl}}</span>
      </Block>

      <Block :title="groupName" no-padded
        v-for="(groupName, i) in Object.keys(groupedCmsItems)" v-bind:key="`cms-item-group${i}`">

        <v-list>
          <template v-for="(item, j) in groupedCmsItems[groupName]">

            <v-divider :key="`group-${i}-${j}`" :inset="item.inset"></v-divider>
            <v-list-item :key="item.uuid">
              <v-list-item-avatar>
                <v-btn icon>
                  <v-icon v-if="item.type === 'FILE'" color="grey lighten-1">mdi-file</v-icon>
                  <v-icon v-else-if="item.type === 'TEXT'" color="grey lighten-1">mdi-format-title</v-icon>
                </v-btn>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title>
                  <span>{{item.name}}</span>
                  <span v-if="item.content || item.filename">
                    <span v-if="item.published" class="publish-status green--text">Publié</span>
                    <span v-else class="publish-status error--text">Non publié</span>
                  </span>
                </v-list-item-title>
                <v-list-item-subtitle v-html="item.description"></v-list-item-subtitle>
                <v-list-item-subtitle v-html="item.description"></v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-action>
                <div class="cms-item-action">
                  <v-btn text @click="openSession(item.uuid)">Editer</v-btn>
                </div>
              </v-list-item-action>
            </v-list-item>
          </template>
        </v-list>

      </Block>
        <Block v-for="(item, i) in cmsItems" v-bind:key="`cms-item-${i}`">

          <template v-slot:header-title>
            <div class="custom-block-title">
              <v-icon v-if="item.type === 'FILE'" color="grey lighten-1">mdi-file</v-icon>
              <v-icon v-else-if="item.type === 'TEXT'" color="grey lighten-1">mdi-format-title</v-icon>
              <h2>{{item.name}}</h2>
            </div>
          </template>

          <template v-slot:header-action>
            <div v-if="item.content || item.filename">
              <div v-if="item.published" class="publish-status green--text">Publié</div>
              <div v-else class="publish-status error--text">Non publié</div>
            </div>
          </template>

          <template v-slot:footer-action>
            <div class="error--text" v-if="error && errorIndex === i">{{error}}</div>
            <v-btn text @click="openSession(item.uuid)">Editer</v-btn>
          </template>

          <p>Version du : {{$formatDate(item.updateDate ? item.updateDate : item.creationDate, 'DD MMMM YYYY')}}</p>
          <div v-html="previewContent(item)"></div>
      </Block>


    </div>
  </div>
</template>

<script>

import { mapState } from 'vuex';
import Block from "@/components/Block"
import edjsHTML from 'editorjs-html'
import _ from 'lodash';

export default {
  name: 'Home',
  components: { Block },
  data() {
    return {
      content: null,
      loading: false,
      error: null,
      showHistory: false,
      historyLoading: false,
      isPublishing: false
    };
  },
  computed: {
    ...mapState({
      cmsItems(state) {
        return state.session.cmsItems.filter(i => i.group === undefined)
      },
      groupedCmsItems(state) {
        return _.groupBy(state.session.cmsItems.filter(i => i.group != null), 'group.name');
      },
      websiteName(state) {
        return state.session.websiteName
      },
      websiteUrl(state) {
        return state.session.websiteUrl
      },
      hasUnpublishedItems(state) {
        return state.session.cmsItems.some(i => !i.published)
      },
    }),
  },
  methods: {
    previewContent(item) {
      if (item.content) {
        const edjsParser = edjsHTML();
        return edjsParser.parse(item?.content ?? null).join('')
      } else return null
    },
    async openSession(uuid) {
      await this.$router.push(`/items/${uuid}`)
    },
    async publish() {

      try {

        this.error = null
        this.isPublishing = true
        await this.$store.dispatch("session/publish")

      } catch (e) {

        this.error = e

      } finally {
        this.isPublishing = false
      }
    },
  }
}
</script>

<style lang="scss">

.publish-status {
  font-weight: bold;
  padding: 0 16px;
}

.cms-item-action {
  display: flex;
}

.custom-block-title {
  display: flex;

  h2 {
    margin-left: 0.5em;
  }
}
</style>

import Vue from 'vue';
import Vuex, { createLogger } from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import SecureLS from 'secure-ls';
import _ from 'lodash';
import session from './modules/session';

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

const ls = new SecureLS({ isCompression: true });
const encryptedLocal = {
  getItem: (key) => {
    const text = ls.get(key);
    return !_.isEmpty(text) ? JSON.parse(text) : text;
  },
  setItem: (key, value) => ls.set(key, JSON.stringify(value)),
  removeItem: (key) => ls.remove(key),
};

const dataState = createPersistedState({
  key: 'cms-store',
  paths: ['session'],
  storage: debug ? localStorage : encryptedLocal,
});

const plugins = [dataState];
if (debug) plugins.push(createLogger());

export default new Vuex.Store({
  modules: {
    session
  },
  strict: debug,
  plugins,
  reducer: state => {
    return {
      session: {
        sessionId: state.session.sessionId,
        cmsItems: state.session.cmsItems,
        websiteName: state.session.websiteName,
      }
    }
  },
});

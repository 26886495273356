{
<template>
  <Block title="Texte actuel" no-margin style="margin-bottom: 1em">

    <div v-if="editItem">
      <div>
        <v-form ref="form">
          <div id="soon-editor"></div>
        </v-form>
      </div>
    </div>
    <div v-else class="message-container" v-html="previewContent"></div>

    <template v-slot:footer-action>
      <v-spacer></v-spacer>
      <span v-if="error" class="error--text">{{error}}</span>
      <v-btn tile text v-if="!editItem" @click="openSession(item.uuid)" color="primary" :loading="openEditSessionLoading">Editer</v-btn>
      <v-btn v-if="editItem" @click="cancel" text>Annuler</v-btn>
      <v-btn v-if="editItem" :disabled="!unsavedChange" color="primary" @click="submitNewContent(item.uuid)" :loading="saveContentLoading">Sauvegarder</v-btn>
    </template>
  </Block>
</template>

<script>
import EditorJS from '@editorjs/editorjs';
import edjsHTML from 'editorjs-html'
import List from '@editorjs/list';
import Block from './Block'
import { isEqual } from 'lodash'

export default {
  name: 'TextEditor',
  components: {Block},
  props: {
    item: Object,
    autofocus: Boolean
  },
  data() {
    return {
      editItem: false,
      content: this.item?.content,
      openEditSessionLoading: false,
      saveContentLoading: false,
      updatedContent: this.item?.content,
      error: null,
      textFormats: [],
    };
  },
  computed: {
    unsavedChange() {
      return !isEqual(this.item?.content ?? "", this.updatedContent)
    },
    previewContent() {
      const edjsParser = edjsHTML();
      let content = this.item?.content;
      return content ? edjsParser.parse(content).join('') : null
    }
  },
  methods: {

    async initEditor() {
      let data1 = this.item?.content ? this.item.content : null;
      const editor = new EditorJS({
        holder: 'soon-editor',
        autofocus: true,
        data: data1,
        tools: {
          list: {
            class: List,
            inlineToolbar: true,
            config: {
              defaultStyle: 'unordered'
            }
          },
        },
        onReady: function() {
          console.log("ready");
        },
        onChange: async function(a) {
          this.updatedContent = await a.saver.save()
        }.bind(this)
      });

      try {
        await editor.isReady;
        console.log('Editor.js is ready to work!');
        /** Do anything you need after editor initialization */
        window.editor = editor
      } catch (reason) {
        console.log(`Editor.js initialization failed because of ${reason}`);
      }
    },
    save() {
      editor.save().then(savedData => {
        this.content = savedData;
      });
    },
    async openSession(uuid) {
      try {
        this.error = null
        this.openEditSessionLoading = true
        await this.$store.dispatch("session/editCMSItem", uuid)
        this.editItem = true
        await this.initEditor()
      } catch (e) {
        this.error = "Impossible d'éditer cet élément"
      } finally {
        this.openEditSessionLoading = false
      }
    },
    async publish(objectId) {
      try {
        this.error = null
        this.isPublishing = true
        await this.$store.dispatch("session/publish", objectId)
      } catch (e) {
        this.error = "La publication à échouée. Veuillez réessayer."
      } finally {
        this.isPublishing = false
      }
    },
    cancel() {
      this.error = null
      this.editItem = false
      this.updatedContent = this.item.content
    },
    async submitNewContent(cmsItemId) {
      try {
        this.error = null
        this.saveContentLoading = true
        await this.$store.dispatch("session/updateCMSItem", {
          id: cmsItemId,
          type: "TEXT",
          content: Object.assign(this.item, { content: this.updatedContent })
        })
        this.editItem = false
      } catch (e) {
        this.error = e
      } finally {
        this.saveContentLoading = false
      }
    },
  },
};
</script>

<style scoped>

</style>
}

<template>
  <div>
    <Block :title="item.name" no-margin>
      <div v-if="editMode">
        <v-form ref="fileForm">
          <v-text-field label="Nom" v-model="modifiedItem.name" :rules="required"></v-text-field>
          <v-text-field label="Nom affiché" v-model="modifiedItem.displayName"></v-text-field>
          <v-textarea label="description" v-model="modifiedItem.description" rows="3"></v-textarea>
          <DateField name="fileDate" label="Date du fichier"
                     placeholder="sélectionnez une date"
                     @on-update="updateExpirationDate"
                     :value="modifiedItem.fileDate" />
          <v-file-input v-model="newFile" label="Fichier" accept="image/*"></v-file-input>
        </v-form>
      </div>
      <div v-else class="field-box">
        <Field label="Nom affiché">{{item.displayName}}</Field>
        <Field label="Nom du fichier">{{item.filename}}</Field>
        <Field label="description">{{item.description}}</Field>
        <Field label="Date du fichier">{{$formatDate(item.fileDate, 'DD/MM/YYYY')}}</Field>
      </div>

      <template v-slot:footer-action>
        <span class="form-error">{{fileEditError}}</span>
        <v-btn v-if="!editMode" tile text @click="editItem" color="primary">Editer</v-btn>
        <div v-else>
          <v-btn @click="cancel" text>Annuler</v-btn>
          <v-btn text color="primary" :loading="fileEditLoading" @click="save"
                 :disabled="!unsavedChange">Sauvegarder</v-btn>
        </div>
      </template>

    </Block>

    <object v-if="showFile"
            class="file-preview"
            :data="filePreview"></object>
  </div>

</template>

<script>
import Block from './Block';
import Field from './Field';
import Vue from "vue"
import DateField from './DateField';
import _ from 'lodash'
export default {
  components: { Block, Field, DateField },
  props: {
    item: Object
  },
  data() {
    return {
      showFile: true,
      editMode: false,
      modifiedItem: Object.assign({}, this.item),
      fileEditError: null,
      fileEditFormValid: false,
      fileEditLoading: false,
      required: [
        value => !!value || "Champ obligatoire",
      ],
      newFile: null,
      error: null
    }
  },
  computed: {
    unsavedChange() {
      return !_.isEqual(this.item, this.modifiedItem) || this.newFile
    },
    filePreview() {
      if (this.newFile) {
        return URL.createObjectURL(this.newFile)
      } else return `http://localhost:8080/publications/files/${this.item.filename}`
    },
  },
  methods: {

    editItem() {
      this.editMode = true
    },
    cancel() {
      this.fileEditError = null
      this.editMode = false
      this.newFile = null
      let originalItem = Object.assign({}, this.item)
      this.modifiedItem = Object.assign({}, originalItem)
    },
    async save() {
      try {
        this.error = null
        this.fileEditLoading = true
        let payload = {
          id: this.modifiedItem.uuid,
          type: "FILE",
          content: Object.assign(this.modifiedItem, this.newFile ? { filename: this.newFile.filename } : {}),
          file: this.newFile
        };
        console.log("payload : " + JSON.stringify(payload))
        await this.$store.dispatch("session/updateCMSItem", payload)
        this.editMode = false
      } catch (e) {
        this.error = e
      } finally {
        this.fileEditLoading = false
      }
    },
    updateExpirationDate(date) {
      Vue.set(this.modifiedItem, date.key, new Date(date.value).toISOString())
    }
  }
};
</script>

<style scoped>
.field-box {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.file-preview {
  width: 100%;
  min-height: 100vh;
}
</style>

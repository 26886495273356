
<template>
  <div class="page page-content">
      <Block class="error-block">
        <h1>Une erreur est survenue</h1>
        <p>Veuillez réessayer ultérieurement</p>
        <span>{{errorMessage}}</span>
      </Block>
  </div>
</template>

<script>
import Block from '../components/Block.vue';

export default {
  name: 'Error',
  components: {Block},
  props: {
    errorMessage: String
  },
  data() {
    return {};
  },
  computed: {},
  methods: {},
  mounted() {},
};
</script>

<style lang="scss" scoped>
.error-block {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 250px;
}
</style>

import axios from 'axios';
import CMSApi from './CMSApi';
import WebsiteAPI from './WebsiteAPI';

axios.defaults.baseURL = process.env.VUE_APP_BACKEND_HOST;

axios.interceptors.request.use((request) => {
  if (request.baseURL === process.env.VUE_APP_BACKEND_HOST) {
    // eslint-disable-next-line no-param-reassign
    request.headers = Object.assign(request.headers, {
      'Access-Control-Allow-Origin': '*',
    });
  }

  return request;
}, (error) => Promise.reject(error));

export {
  CMSApi,
  WebsiteAPI
};

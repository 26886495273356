import axios from 'axios';

export default {

  async getWebsite() {
      const response = await axios.get('/cms');
      return response.data;
  },
  async initializeRepository() {
      const response = await axios.get('/cms/website/init');
      return response.data;
  },
  async updateCMSItem(sessionId, payload) {

    let url = `/cms/${sessionId}/object/${payload.id}`

    let content = payload.content
    let data = content

    if (payload.type === "FILE") {
      url += "/upload"
      data = new FormData();
      Object.keys(content).forEach(key => {
        console.log("adding key : " + JSON.stringify(key))
        content.append(key, content[key])
      })
      content.append("file", payload.file);
    }

    const response = await axios.put(url, data);
    return response.data;
  },
  async getCMSITEmHistory(sessionId, objectId) {
      const response = await axios.get(`/cms/${sessionId}/object/${objectId}`);
      return response.data;
  },

  async getCMSItem(sessionId, objectId) {
    const response = await axios.get(`/cms/${sessionId}/object/${objectId}`);
    return response.data;
  },

  async publishSession(sessionId) {
    const response = await axios.post(`/cms/${sessionId}/publish`);
    return response.data;
  },
  async editCMSItem(sessionId, objectId) {
    const response = await axios.post(`/cms/${sessionId}/object/${objectId}/edit`)
    return response.data
  }
};

import {CMSApi} from '@/api';
import _ from 'lodash';

const state = () => ({
  sessionId: null,
  cmsItems: [],
  websiteName: null,
  loading: false,
  isAppLoading: false,
  error: null,
  ready: false
});

const getters = {
  getSessionId: (state) => state.sessionId,
  getCMSItems: (state) => state.cmsItems,
  getCMSItemsById: (state) => (id) => {
    return state.cmsItems.find(i => i.uuid === id)
  },
  isReady: (state) => state.ready
};

const actions = {

  async initializeRepository({ commit }) {

    try {

      commit("setAppLoading", true)
      const response = await CMSApi.initializeRepository();
      commit('setCMSSession', response)

      return response;
    } catch (e) {
      throw "Impossible d'initialiser le contenu"
    } finally {
      commit("setAppLoading", false)
    }
  },

  async editCMSItem({state}, objectId) {

    try {
      return await CMSApi.editCMSItem(state.sessionId, objectId)
    } catch (e) {
      if (e.response.status === 404) throw "Session expirée"
      else throw "Une erreur est survenue. Veuillez réessayer."
    }
  },

  async updateCMSItem({state, commit}, payload) {

    try {
      const response = await CMSApi.updateCMSItem(state.sessionId, payload)
      commit('updateCMSItem', response)
      return response
    } catch (e) {
      throw "La sauvegarde à échouée."
    }
  },

  async getCMSItem({state, commit}, objectId) {
    const item = await CMSApi.getCMSItem(state.sessionId, objectId)
    commit('updateCMSItem', item)
    return item
  },

  async publish({state, commit}) {
    let session = await CMSApi.publishSession(state.sessionId);
    commit('setCMSSession', session)
    return session
  }
};

const mutations = {

  setCMSSession(state, session) {
    state.ready = true
    state.sessionId = session.id;
    state.websiteName = session.website.name
    state.websiteUrl = session.website.url
    state.cmsItems = session.website.items
    if (session.website.files) state.cmsItems.push(...session.website.files)
  },
  setAppLoading(state, value) {
    state.isAppLoading = value
  },
  setCMSItems(state, { items, files }) {
    console.log("items : " + JSON.stringify(items))
    console.log("files : " + JSON.stringify(files))

  },
  updateCMSItem(state, item) {
    const index = _.findIndex(state.cmsItems, (i) => i.uuid === item.uuid)
    if (index !== -1) {
      state.cmsItems.splice(index, 1, item)
    }
    else state.cmsItems.push(item)
  },
  updateCMSItemHistory(state, {objectId, history}) {
    const index = _.findIndex(state.cmsItems, (i) => i.uuid === objectId)
    if (index !== -1) {
      state.cmsItems = state.cmsItems.splice(index, 1, history)
    }
  },
  setLoading(state, status) {
    if (status) state.error = null
    state.loading = status
  },
  setError(state, error) {
    state.error = error
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};

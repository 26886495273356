<template>
  <div class="field-wrapper">
    <div class="label">{{label}}</div>
    <div class="value">{{value}}</div>
    <slot/>
  </div>
</template>

<script>
export default {
  props: {
    label: String,
    value: String
  },
}
</script>

<style lang="scss" scoped>

.field-wrapper {
  margin: 0.5em 0;
  min-width: 33%;
  .label {
    color: rgba(0,0,0,.6);
    height: 20px;
    line-height: 20px;
    letter-spacing: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    top: 6px;
    white-space: nowrap;
    font-size: smaller;
  }

  .value {

  }
}

</style>

import Vue from 'vue';
import App from './App.vue';
import axios from 'axios';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import webUtils from "@muskacirca/vue-web-utils"
import {keycloakOptions, VueKeyCloak} from "./plugins/keycloak";
import VueCompositionApi from '@vue/composition-api'

Vue.use(VueCompositionApi)

Vue.use(webUtils)

Vue.config.productionTip = false;

Vue.use(VueKeyCloak, Object.assign(keycloakOptions, {
  onReady: () => {
    axios.interceptors.request.use(config => {
      if (Vue.prototype.$keycloak.authenticated) {
        config.headers.Authorization = `Bearer ${Vue.prototype.$keycloak.token}`
      }
      return config
    }, error => {
      console.log("error : " + JSON.stringify(error))
      return Promise.reject(error)
    })

    new Vue({
      vuetify,
      router,
      store,
      render: h => h(App)
    }).$mount('#app')
  }
}))

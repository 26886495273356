<template>
  <v-app id="main-app">
    <Overlay :active="isAppLoading">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </Overlay>
    <v-app-bar color="primary" clipped-left app flat fixed width="100%" dark>
      <div class="d-flex align-center">
        <router-link to="/" class="header__title">Editeur de contenu</router-link>
      </div>

      <v-spacer></v-spacer>

      <v-menu offset-y offset-x>
        <template v-slot:activator="{ on, attrs }">

          <v-icon large v-on="on" v-bind="attrs">
            mdi-account-circle-outline
          </v-icon>
        </template>

        <v-list>

          <h3 style="padding: 1em">Bonjour {{$keycloak.tokenParsed.name}}</h3>
          <v-list-item v-for="(item, index) in menuItems" :key="index">
            <v-list-item-title class="link" @click="logout">{{ item.title }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
    <v-main id="app-content">
      <router-view :key="$route.fullPath"/>
    </v-main>
  </v-app>
</template>

<script>

import { mapState } from 'vuex';
import Overlay from './components/Overlay';

export default {
  name: 'App',
  components: {Overlay},
  data() {
    return {
      showSideNav: !this.$isMobile(),
      mini: false,
      invoices: [],
      showSubList: false,
      menuItems: [
        { title: 'Se déconnecter' }
      ],
      navigationsItems: [{
        name: "Paramètres",
        icon: "mdi-cog",
        link: "/settings"
      }]
    }
  },
  computed: {
    ...mapState({
      websiteName(state) {
        return state.session.websiteName
      },
      cmsItems(state) {
        return state.session.cmsItems
      },
      isAppLoading(state) {
        return state.session.isAppLoading
      }
    }),
  },
  methods: {
    logout() {
      this.$keycloak.logoutFn()
    }
  },
  async mounted() {

    try {
      await this.$store.dispatch("session/initializeRepository")
    } catch (e) {
      await this.$router.push('/error?id=init-app-error')
    }
  }
};
</script>

<style lang="scss">

body, #main-app, #app-content{
  background-color: #F5F5F6;
}

  .header__title {
    font-size: xx-large;
    text-decoration: none;
    color: white !important;
  }

  .link {
    cursor: pointer;
    color: #1A76D2;
  }

  .link:hover {
    text-decoration: underline;
  }

  .page {
    display: flex;
    flex-direction: column;
  }

  .page.in-row {
    flex-direction: row;
  }

  .page-content {
    max-width: 1280px;
    margin: 0 auto;
    padding-top: 1em;
    padding-bottom: 3em;
  }

  .page-content.no-padding {
    padding: 0;
  }

  @media only screen and (max-width: 1000px) {
    .page-content {
      max-width: 1280px;
      margin: 0 auto;
      padding: 1em 1em 3em;
    }

    .header__title {
      font-size: x-large;
      text-decoration: none;
      color: white !important;
    }
  }


  #main-app {
    display: flex;
    flex-direction: column;
    height: 100%;

    #app-content {
      display: flex;
    }
  }
</style>

<template>
  <div class="item-page" style="display: flex">
    <v-navigation-drawer permanent>
      <v-list>
        <v-list-item link :href="websiteUrl" target="_blank">
          <v-list-item-content>
            <v-list-item-title class="text-h6">
              {{ websiteName}}
            </v-list-item-title>
            <v-list-item-subtitle>{{ websiteUrl }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-divider></v-divider>

      <v-list nav>

        <v-list-item link :to="item.link" v-for="(item, i) in nonGroupedMenuItems" v-bind:key="`menu-${i}`">
          <v-list-item-title>{{ item ? item.name : null }}</v-list-item-title>
        </v-list-item>

        <v-list-group v-for="(group, i) in groupedMenuItems" v-bind:key="`grouped-menu-${i}`" no-action>
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>{{ group.name }}</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item link :to="item.link" v-for="(item, j) in group.sublist"
                       v-bind:key="`grouped-${group.name}-${i}-${j}`" >
            <v-list-item-title  v-text="item.name"></v-list-item-title>
          </v-list-item>
        </v-list-group>
      </v-list>
    </v-navigation-drawer>
    <div class="cms-items-container page-content">
      <div v-for="(it, i) in item" v-bind:key="`group-item-${i}`" class="cms-item-wrapper">
        <div class="header">
          <div class="header-actions">
            <h1>{{`Elément modifiable : ${it ? it.name : null}`}}</h1>
            <div v-if="it && (it.content || it.filename)">
              <div v-if="it && it.published" class="publish-status green--text">Publié</div>
              <div v-else class="publish-status error--text">Non publié</div>
            </div>
          </div>
          <div class="header-actions">
            <div class="error--text" v-if="mainError">{{mainError}}</div>
            <!--        <v-btn text v-if="!editItem" @click="editItem = true" >-->
            <!--          <v-icon left>mdi-eye</v-icon>Apercu-->
            <!--        </v-btn>-->
  <!--            <div v-if="it && (it.content || it.filename)">-->
  <!--              <v-btn v-if="it && !it.published" color="primary" @click="publish(it.uuid)" :loading="isPublishing" elevation="0">Publier</v-btn>-->
  <!--            </div>-->
          </div>
        </div>

        <v-skeleton-loader v-if="!it"></v-skeleton-loader>
        <TextEditor v-else-if="it.type === 'TEXT'" autofocus :item="it" />
        <FileEditor v-else-if="it.type === 'FILE'" autofocus :item="it" />


        <div v-if="it && it.type === 'TEXT' && it.history && it.history.length > 0">
          <h2>Historique</h2>
          <Block :title="`Version du ${$formatDate(history.date, 'DD MMMM YYYY')}`" v-for="(history, j) in it.history" v-bind:key="`cms-item-${item.id}-history-${j}`"
                 no-margin style="margin-bottom: 1em">
            <div class="message-container" v-html="history.content"></div>
          </Block>
        </div>
        <div v-else-if="it && it.type === 'TEXT'" style="display: flex">
          <v-btn @click="fetchHistory(it)" elevation="0">Charger l'historique</v-btn>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import Block from "../components/Block"
import { mapState } from 'vuex';
import TextEditor from '../components/TextEditor';
import _ from 'lodash'
import FileEditor from '../components/FileEditor';

export default {
  name: 'CMSItem2',
  components: { FileEditor, TextEditor, Block },
  data() {
    return {
      editItem: false,
      loading: false,
      historyLoading: false,
      isPublishing: false,
      mainError: null,
      itemId: null,
      groupId: null
    }
  },
  computed: {
    ...mapState({
      item(state) {

        if (this.groupId) {
          return state.session.cmsItems.find(i => {
            return i.group && i.group.id == this.groupId
          })
        } else return [state.session.cmsItems.find(i => {
          return i.uuid === this.itemId
        })]
      },
      nonGroupedMenuItems(state) {
        return state.session.cmsItems
          .filter(i => i.group === undefined)
          .map(i => {
            return {
              link: `/items/${i.uuid}`,
              name: i.name
            }
          });
      },
      groupedMenuItems(state) {

        let groupedItems = state.session.cmsItems.filter(i => i.group !== undefined)
        let groupBy = _.groupBy(groupedItems, 'group.name');
        return Object.keys(groupBy)
          .map(key => {
            let item = groupBy[key];
            return {
              name: key,
              sublist: item.map(i => {
                return {
                  link: `/items/${i.uuid}`,
                  name: i.name
                }
              })
            }
          })
      },
      websiteName(state) {
        return state.session.websiteName
      },
      websiteUrl(state) {
        return state.session.websiteUrl
      }
    }),
  },
  methods: {
    async openSession(uuid) {
      try {
        await this.$store.dispatch("session/editCMSItem", uuid)
        this.editItem = true
      } catch (e) {
        this.mainError = "Impossible d'éditer cet élément"
      }
    },
    async publish(objectId) {
      try {
        this.isPublishing = true
        await this.$store.dispatch("session/publish", objectId)
      } catch (e) {
        this.mainError = "La publication à échouée. Veuillez réessayer."
      } finally {
        this.isPublishing = false
      }
    },
    cancel() {
      this.editItem = false
      this.updatedContent = this.item.content
    },
    async submitNewContent(cmsItemId) {
      try {
        this.error = null
        this.loading = true
        await this.$store.dispatch("session/updateCMSItem", {
          id: cmsItemId,
          content: this.updatedContent
        })
        this.editItem = false
      } catch (e) {
        this.error = e
      } finally {
        this.loading = false
      }
    },
    async fetchHistory(item) {

      try {
        this.historyLoading = true
        await this.$store.dispatch("session/getCMSItem", item.uuid);
        this.showHistory = true
      } catch (e) {
        this.error = e
      } finally {
        this.historyLoading = false
      }
    },
    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms))
    }
  },
  beforeRouteUpdate(to, from, next) {
    if (to.params.itemId !== from.params.itemId) {
      this.itemId = to.params.itemId
    }
    next()
  },
  updated() {

    console.log("update");


  },
  mounted() {
    this.itemId = this.$route.params.itemId
    this.groupId = this.$route.params.groupId
  }
};
</script>

<style lang="scss" scoped>

.item-page {
  display: flex;
  height: 100%;

  .cms-items-container {
    display: flex;
    flex: 1;
    flex-direction: column;
    .cms-item-wrapper {

      flex: 1;
      padding: 1em;

      .header {
        display: flex;
        justify-content: space-between;
      }

      .header-actions {
        display: flex;
        align-items: center;

        div {
          margin-right: 1em;
        }

        .v-btn {
          margin-right: 1em;
        }

        .v-btn:last-child {
          margin-right: 0;
        }

        div:last-child {
          margin-right: 0;
        }
      }

      .padded {
        padding: 32px;
      }
      .message-container {
        white-space: pre-wrap;
      }
    }
  }
}


</style>

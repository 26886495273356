import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import Error from '../views/Error.vue';
import CMSItem from '../views/CMSItem2.vue'

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    component: Home,
  },
  {
    path: '/items/:itemId',
    component: CMSItem
  },
  {
    path: '/groups/:groupId',
    component: CMSItem
  },
  {
    path: '/error',
    component: Error
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;

import VueKeyCloak from '@dsb-norge/vue-keycloak-js'

const keycloakOptions = {
  init: {
    onLoad: 'login-required',
    silentCheckSsoRedirectUri: window.location.origin + "/silent-check-sso.html"
  },
  config: {
    url: `${process.env.VUE_APP_IDS_HOST}`,
    realm: 'soon-agency',
    clientId: 'cms-web',
    "enable-cors": true,
    checkLoginIframeInterval: 5,
    enableLogging: true,
    flow: "implicit"
  },
  logout: {
    redirectUri: `${process.env.VUE_APP_HOST}/`
  },
}

export {
  VueKeyCloak,
  keycloakOptions
}

